@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HoltwoodOneSC';
  src: local('HoltwoodOneSC'), url(./assets/fonts/HoltwoodOneSC-Regular.ttf) format('opentype');;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'ComingSoon';
  src: local('ComingSoon'), url(./assets/fonts/ComingSoon-Regular.ttf) format('opentype');;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
body,
#root {
  min-height: 100vh;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-ComingSoon {
  font-family: ComingSoon;
}

.text-ComingSoon-800 {
  font-family: ComingSoon;
  font-weight: 800;
}

.text-HoltwoodOneSC {
  font-family: HoltwoodOneSC;
}

.text-raleway {
  font-family: 'Raleway', sans-serif;
}

.sd-cookie {
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 0px;
  clear: both;
  border-top: 1px solid rgba(170, 170, 170, 0.2);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.sd-box-services {
  transition: ease all 0.5s;
}

.sd-box-services:hover {
  transform: scale(1.1);
  z-index: 999;
}

.navigation-shadow {
  box-shadow: 0 0 10px #7b7f84;
}

.body-lock {
  overflow: hidden;
}

.sorab-bg-main {
  /*background: url('./assets/images/sorab-bg-main.png');*/
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #02112dd4;
}

table {
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}


@media only screen and (min-width: 768px) {
 table {
   width: 70%;
 }
  .body-lock .sorab-nav {
    padding-right: 10px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lazy-load-image-loaded {
  display: block !important;
}

.animation-bg {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1660px) and (min-width: 1542px)  {
  .slo-responsive {
    font-size: 18px !important;
  }
  .slo-responsive-links {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}